import { Component, createContext, ReactNode, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import mapboxGL, { LngLatLike, Map, MapboxGeoJSONFeature, MapboxOptions } from "mapbox-gl";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { setMapStyle } from "@app/store/userPreferences/userPreferences.actions";
import { getApplicationPreferences } from "@app/store/userPreferences/userPreferences.selector";
import {
    BASE_MAP_NODE_ID,
    DEFAULT_BASE_MAP_STYLE,
    DEFAULT_CENTER_COORDINATES,
    DEFAULT_MAX_ZOOM_LEVEL,
    DEFAULT_MIN_ZOOM_LEVEL,
    DEFAULT_ZOOM_LEVEL,
    MAP_DATA_LOADING_ERROR_MESSAGE,
    MAP_LOADER_ANIMATION_CONFIGURATION,
} from "@common/components/baseMap/baseMap.constants";
import { baseMapService } from "@common/components/baseMap/baseMap.service";
import {
    EControlPositions,
    EMapStyleIds,
    TControlConfig,
} from "@common/components/baseMap/baseMap.types";
import { useMeasurementToolActive } from "@common/components/baseMap/customControls/measurementTool";
import { ILocation } from "@common/components/geocoder/geocoder";
import { StlLoader } from "@common/components/loader";
import { MAPBOX_ACCESS_TOKEN } from "@common/constants/mapbox.constants";
import { useDidMount } from "@common/hooks/useDidMount";
import { useWillUnmount } from "@common/hooks/useWillUnmount";
import { EventManager } from "@common/utils/eventManager";
import { useMounted } from "@common/utils/useMounted";
import { useMapControls } from "./hooks/internal/useMapControls/useMapControls";
import { useMapResize } from "./hooks/internal/useMapResize";
import { useMapState } from "./hooks/internal/useMapState";
import { useMapStyle, waitTillMapLoad } from "./hooks/internal/useMapStyle";
import { MapStyleLoadingAlert } from "./hooks/internal/useMapStyleLoading/mapStyleLoadingAlert";
import { useMapStyleLoading } from "./hooks/internal/useMapStyleLoading/useMapStyleLoading";
import { IMapError, MapErrorAlert } from "./mapErrorAlert/mapErrorAlert";
import {
    getBaseMapStyleUrl,
    getFallbackBaseMapStyleId,
    withVerifySourceAndLayerPrefixes,
} from "./baseMap.helpers";
import "./baseMap.less";
import "./baseMapDebug.service";

mapboxGL.accessToken = MAPBOX_ACCESS_TOKEN;
const WEBGL_INIT_ERROR_MESSAGE = "Failed to initialize WebGL." as const;

type TMapContext = {
    map: Map | null;
    hoveredFeature: MapboxGeoJSONFeature | null;
    setHoveredFeature: (feature: MapboxGeoJSONFeature | null) => void;
};

export const MapContext = createContext<TMapContext>({
    map: null,
    hoveredFeature: null,
    setHoveredFeature: () => {},
});

type TBaseMapProps = {
    id?: string;
    maxZoom?: number;
    minZoom?: number;
    className?: string;
    isLoading?: boolean;
    style?: EMapStyleIds;
    children?: ReactNode;
    location?: ILocation | null;
    topLeftControls?: ReactNode;
    topRightControls?: ReactNode;
    bottomLeftControls?: ReactNode;
    bottomRightControls?: ReactNode;
    baseTopLeftControl?: ReactNode;
    baseBottomLeftControl?: ReactNode;
    controlsConfig?: Partial<TControlConfig>;
    onLoad?: (map: Map | null) => void;
    error?: IMapError | null;
    setError?: (error: IMapError | null) => void;
    onStyleChange?: (style: EMapStyleIds) => void;
    onLocationChange?: (location: ILocation) => void;
    ariaLabel?: string;
    interactive?: boolean;
};

type TInitMapConfig = {
    maxZoom?: number;
    minZoom?: number;
    style: EMapStyleIds;
    enableAntialiasing: boolean;
    containerNode: MapboxOptions["container"];
    interactive: boolean;
};

const initMap = ({
    containerNode,
    style,
    enableAntialiasing,
    maxZoom = DEFAULT_MAX_ZOOM_LEVEL,
    minZoom = DEFAULT_MIN_ZOOM_LEVEL,
    interactive,
}: TInitMapConfig) => {
    const map: Map & { eventManager?: EventManager } = new mapboxGL.Map({
        container: containerNode,
        style: getBaseMapStyleUrl(style),
        center: DEFAULT_CENTER_COORDINATES as LngLatLike,
        zoom: DEFAULT_ZOOM_LEVEL,
        minZoom,
        maxZoom,
        scrollZoom: true,
        pitch: 0,
        bearing: 0,
        antialias: enableAntialiasing,
        interactive,
    });

    map.eventManager = new EventManager();

    // Expose map instance to global scope for debug in console and screenshots
    window.BASE_MAP = map;

    // @ts-ignore Cannot find name 'IS_DEVELOPMENT'
    if (IS_DEVELOPMENT) withVerifySourceAndLayerPrefixes(map);

    return map;
};

const INITIAL_STATE = {
    map: null,
    isMapLoading: false,
    mapContainerRef: null,
    mapInitError: false,
};

const _BaseMap = ({
    id: mapId,
    className,
    isLoading = false,
    error,
    setError,
    onLoad = () => {},
    children,
    topLeftControls,
    topRightControls,
    bottomLeftControls,
    bottomRightControls,
    baseTopLeftControl,
    baseBottomLeftControl,
    controlsConfig,
    style,
    onStyleChange = () => {},
    onLocationChange = () => {},
    location,
    maxZoom,
    minZoom,
    ariaLabel,
    interactive = true,
}: TBaseMapProps) => {
    const [map, setMap] = useState<Map | null>(INITIAL_STATE.map);
    const [isMapLoading, setIsMapLoading] = useState<boolean>(INITIAL_STATE.isMapLoading);

    // We need to set only one hovered feature for all layers in order to avoid hover outline
    // animation on several zones simultaneously.
    const [hoveredFeature, setHoveredFeature] = useState<MapboxGeoJSONFeature | null>(null);

    const { vectorMapSettings, mapStyle: _mapStyle } = useAppSelector(getApplicationPreferences);

    const dispatch = useAppDispatch();
    const isMounted = useMounted();
    const mapContainerRef = useRef<HTMLDivElement | null>(INITIAL_STATE.mapContainerRef);

    const mapStyle = useMemo(
        () => style || _mapStyle || DEFAULT_BASE_MAP_STYLE.styleId,
        [style, _mapStyle],
    );

    const onMapStyleChange = (newStyle: EMapStyleIds) => {
        if (newStyle !== _mapStyle) {
            onStyleChange(newStyle);
            if (!style) {
                dispatch(setMapStyle(newStyle));
            }
        }
    };

    useDidMount(() => {
        const initMapConfig: TInitMapConfig = {
            containerNode: mapContainerRef.current ? mapContainerRef.current : "",
            style: mapStyle,
            enableAntialiasing: vectorMapSettings.enableAntialiasing,
            maxZoom,
            minZoom,
            interactive,
        };

        const initMapHelper = (_initMapConfig: TInitMapConfig = initMapConfig) => {
            const _mapInstance = initMap(_initMapConfig);
            waitTillMapLoad(_mapInstance, (...args) => {
                if (isMounted.current) {
                    setMap(_mapInstance);
                    baseMapService.setMap(_mapInstance, mapId);
                    onLoad(...args);
                }
            });

            return _mapInstance;
        };

        const mapInstance = initMapHelper();

        mapInstance.once("error", (err: ErrorEvent) => {
            if (
                !mapInstance.isStyleLoaded() &&
                err.error.message === MAP_DATA_LOADING_ERROR_MESSAGE
            ) {
                setError?.({
                    errorTexts: [
                        <MapStyleLoadingAlert
                            key="StyleLoadingError"
                            onHide={() => {
                                const fallbackStyle = getFallbackBaseMapStyleId(mapStyle);
                                onMapStyleChange(fallbackStyle);
                                setError?.(null);
                                initMapHelper({ ...initMapConfig, style: fallbackStyle });
                            }}
                        />,
                    ],
                });
            }
        });
    });

    useWillUnmount(() => {
        baseMapService.clearMap(mapId);
    });

    useMapState(map, { setIsLoading: setIsMapLoading });
    useMapStyle(map, { style: mapStyle, isMounted, onMapLoad: onLoad });
    useMapStyleLoading(map, { style: mapStyle, onStyleChange: onMapStyleChange, setError });
    useMapResize(map, mapContainerRef);

    const isMeasurementToolActive = useMeasurementToolActive(map);

    const {
        controls,
        mountBaseMapConfigurationModal,
        mountZoomToLocationInput,
        mountMapLayersControl,
        mountMeasurementTool,
        mountMapLoaderControl,
    } = useMapControls({
        map,
        mapId,
        configuration: controlsConfig,
        mapContainerRef,
        style: mapStyle,
        onStyleChange: onMapStyleChange,
        isLoading: isMapLoading,
        location,
        onLocationChange,
    });

    const mountErrorAlert = () => {
        if (isLoading || isMapLoading) return null;

        return (
            <MapErrorAlert
                error={error}
                onHide={() => {
                    if (setError) setError(null);
                }}
            />
        );
    };

    const shouldMoveLoader =
        isMeasurementToolActive &&
        (!controlsConfig?.position || controlsConfig?.position === EControlPositions.TOP_LEFT);

    const sharedData = useMemo(
        () => ({
            map,
            hoveredFeature,
            setHoveredFeature,
        }),
        [map, hoveredFeature, setHoveredFeature],
    );

    return (
        <MapContext.Provider value={sharedData}>
            <div
                className={classNames("stl-base-map", className)}
                id={BASE_MAP_NODE_ID}
                ref={mapContainerRef}
                aria-label={ariaLabel || "Map container"}
            >
                <div className="top-left-wrapper">
                    <div>
                        {baseTopLeftControl}
                        {topLeftControls}
                    </div>
                </div>
                <div className="top-right-wrapper">{topRightControls}</div>
                <div className="bottom-left-wrapper">
                    {baseBottomLeftControl}
                    {bottomLeftControls}
                </div>
                <div className="bottom-right-wrapper">{bottomRightControls}</div>
                {!controls.mapLoader && (
                    <StlLoader
                        className={classNames(
                            "stl-viz3-loader",
                            shouldMoveLoader && "move-to-right",
                        )}
                        animationConfig={MAP_LOADER_ANIMATION_CONFIGURATION}
                        show={isLoading}
                        size="small"
                    >
                        Fetching data...
                    </StlLoader>
                )}
                {children}
                {mountErrorAlert()}
                {mountBaseMapConfigurationModal()}
                {mountZoomToLocationInput()}
                {mountMapLayersControl()}
                {mountMeasurementTool()}
                {mountMapLoaderControl()}
            </div>
        </MapContext.Provider>
    );
};

export class BaseMap extends Component<TBaseMapProps> {
    state = {
        mapInitError: INITIAL_STATE.mapInitError,
    };

    static getDerivedStateFromError(error: Error) {
        return { mapInitError: error?.message === WEBGL_INIT_ERROR_MESSAGE };
    }

    render() {
        if (this.state.mapInitError) {
            return (
                <div className="stl-base-map init-error">
                    <span className="reload-need">Please restart your browser.</span>
                    <br />
                    Failed to initialize map.
                </div>
            );
        }

        return <_BaseMap {...this.props} />;
    }
}
