import { getBasics, travelModeObjSelector } from "@app/analysis/basics/state/basics.selectors";
import {
    CREATE_ANALYSIS_TYPES,
    SCREEN_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    getAnalysisTravelModeFromCode,
    getAnalysisTypeFromCode,
    getIsAnalysisTypeSupportOption,
} from "@app/analysis/state/analysisConfiguration.helpers";
import {
    analysisTypeObjSelector,
    getAnalysisTypeCode,
    getGeneral,
    getIsReadOnlyMode,
    isAnalysisTypeOf,
} from "@app/analysis/state/general/general.selectors";
import {
    DAY_CODES,
    TIME_PERIODS_TAB_SECTIONS,
    WEEKDAY_TYPES,
} from "@app/analysis/timePeriods/state/timePeriods.constants";
import {
    getHasCensus2020DataPeriods,
    getHasCVDUpsampledMetricsDates,
    getHasTimePeriodWithCVDLimitedTravelerAttrs,
    getIsDataPeriodsMixedWithCensus2020,
    getIsOnlyCensus2020DataPeriods,
    getWeekdayTypeById,
    hasExcludedMediumDutyDataPeriods,
} from "@app/analysis/timePeriods/state/timePeriods.helpers";
import { getIsOrgHasFeature } from "@app/store/currentUser/currentUser.selector";
import { getPreferredWeekdayType } from "@app/store/userPreferences/userPreferences.selector";
import {
    ANALYSIS_CENSUS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
} from "@common/constants/analysis.constants";
import { getIsAllVehiclesTravelMode, getIsBikeOrPedTravelMode } from "@common/helpers/analysis";
import moment from "moment";
import { createSelector } from "reselect";

/*
    Getter of the main object, just for the sake of convenience.
*/
export function getTimePeriods(state) {
    return state.analysisConfiguration.timePeriods;
}

export function getTimePeriodsValidation(state) {
    return getTimePeriods(state).validation;
}

/*
    Getters sub-objects of the main one. Just for the sake of convenience.
*/

export const getDataPeriodSettings = state => getTimePeriods(state).dataPeriodSettings;
export const getDayPartSettings = state => getTimePeriods(state).dayPartSettings;
export const getDayTypeSettings = state => getTimePeriods(state).dayTypeSettings;
export const getAadtSettings = state => getTimePeriods(state).aadtSettings;
export const getDataMonthSettings = state => getTimePeriods(state).dataMonthSettings;
export const getUseCustomRanges = state => getTimePeriods(state).shouldUseCustomRanges;
/*
    This getters should be private (used only in this file) and are necessary only for proper
    `createSelector` memoization. If you want to get those properties, please use parent object.
*/
const getExcludedDataPeriods = state => getDataPeriodSettings(state).excludedDataPeriods;
const getDayParts = state => getDayPartSettings(state).dayParts;
const getDayTypes = state => getDayTypeSettings(state).dayTypes;
const getDataPeriods = state => getDataPeriodSettings(state).dataPeriods;
const getDataMonths = state => getDataMonthSettings(state).dataMonths;

/*
    Other getters which are used in different places.
    Please create new ones with caution. We definitely don't want this file become the place
    where functions used only once and/or don't serve as helpers.
*/

export const isDataPeriodsSectionDisabled = state =>
    isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code, state);

export const isDayTypesSectionDisabled = state =>
    !getIsOrgHasFeature(state, "day_part_day_type") ||
    isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code, state);

export const isDayPartsSectionDisabled = state =>
    !getIsOrgHasFeature(state, "day_part_day_type") ||
    isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code, state) ||
    isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.CONGESTION.code, state);

export const getAvailableTimePeriodsTabSections = state => {
    const sectionsPermissions = {
        [TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS.code]: {
            isDisabled: false,
        },
        [TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS.code]: {
            isDisabled: isDataPeriodsSectionDisabled,
        },
        [TIME_PERIODS_TAB_SECTIONS.DAY_TYPES.code]: {
            isDisabled: isDayTypesSectionDisabled,
        },
        [TIME_PERIODS_TAB_SECTIONS.DAY_PARTS.code]: {
            isDisabled: isDayPartsSectionDisabled,
        },
    };

    const analysisTypeObj = analysisTypeObjSelector(state);

    return analysisTypeObj.timePeriods.map(option => {
        const sectionPermissions = sectionsPermissions[option.code] || {};

        const isDisabled = !!sectionPermissions.isDisabled && sectionPermissions.isDisabled(state);

        return {
            code: option.code,
            ...(isDisabled && { isDisabled }),
        };
    });
};

/*
    re-select selectors.
    Implements memoization. Use them in case of some calculations you need to perform together
    with store selectors.
*/

export const showAddDataPeriodsSelector = createSelector(getDataPeriods, dataPeriods => {
    const hasPeriodsInEditMode = dataPeriods.some(
        dataPeriod => dataPeriod.status === "editing" || dataPeriod.status === "adding",
    );

    return !hasPeriodsInEditMode;
});

export const showAddExclusionDataPeriodsSelector = createSelector(
    getExcludedDataPeriods,
    excludedDataPeriods => {
        const hasPeriodsInEditMode = excludedDataPeriods.some(
            dataPeriod => dataPeriod.status === "editing" || dataPeriod.status === "adding",
        );

        return !hasPeriodsInEditMode;
    },
);

export const showAddDayPartsSelector = createSelector(getDayParts, dayParts => {
    const hasPartsInEditMode = dayParts.some(part => part.editMode);

    return !hasPartsInEditMode;
});

export const showAddDayTypesSelector = createSelector(getDayTypes, dayTypes => {
    const hasTypesInEditMode = dayTypes.some(type => type.editMode);

    return !hasTypesInEditMode;
});

export const dayTypesToCodeSelector = createSelector(getDayTypes, dayTypes =>
    dayTypes
        .filter(dayType => dayType.name && dayType.start && dayType.end)
        .map(dayType => {
            let dayTypeCode = "";

            for (const key in dayType) {
                if (dayType.hasOwnProperty(key)) {
                    switch (key) {
                        case "name":
                            dayTypeCode = `${dayType[key]}|`;
                            break;

                        case "start":
                        // fall through

                        case "end":
                            dayTypeCode += DAY_CODES[dayType[key].name].code;
                            break;
                    }
                }
            }

            return dayTypeCode;
        })
        .join(","),
);

export const getIsCustomRangesModeSupported = createSelector(
    getAnalysisTypeCode,
    state => travelModeObjSelector(state),
    state => getIsOrgHasFeature(state, "specific_project_dates"),
    (analysisTypeCode, travelMode, isProjectSpecificDatesEnabled) => {
        const analysisType = getAnalysisTypeFromCode(analysisTypeCode);
        return (
            analysisType.supportsCustomRanges &&
            travelMode.editableDataDateRanges &&
            isProjectSpecificDatesEnabled
        );
    },
);

export const getIsAvailableSpecificProjectDates = createSelector(
    getIsCustomRangesModeSupported,
    getUseCustomRanges,
    state => getIsOrgHasFeature(state, "specific_project_dates"),
    (isCustomRangesModeSupported, shouldUseCustomRanges, isSpecificProjectDatesEnabled) => {
        return isCustomRangesModeSupported ? shouldUseCustomRanges : isSpecificProjectDatesEnabled;
    },
);

// If specific_project_dates is set or selected Travel Mode has editable Date Ranges,
// date_format should be 'date_ranges', otherwise 'data_periods'.
// For K_FACTOR_ESTIMATION type: date_format should be 'data_periods'.
// For analyses supporting new Custom Ranges section: if 'shouldUseCustomRanges' is true,
// date_format should be 'date_ranges', otherwise 'data_periods'.
export const isDateRangeFormat = createSelector(
    getIsAvailableSpecificProjectDates,
    state => travelModeObjSelector(state),
    getAnalysisTypeCode,
    getTimePeriods,
    getIsCustomRangesModeSupported,
    (
        isAvailableProjectSpecificDates,
        travelMode,
        analysisTypeCode,
        { shouldUseCustomRanges },
        isCustomRangesModeSupported,
    ) => {
        if (isCustomRangesModeSupported) {
            return shouldUseCustomRanges;
        } else if (
            analysisTypeCode === CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code ||
            !travelMode.editableDataDateRanges
        ) {
            return false;
        }
        return isAvailableProjectSpecificDates;
    },
);

export const serializedSelectedDataMonthsSelector = createSelector(
    getDataMonths,
    isDateRangeFormat,
    state => isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.AADT.code, state),
    state => isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code, state),
    (dataMonths, getIsDateRangeFormat, isAADTAnalysis, isKFactorAnalysis) => {
        if (isAADTAnalysis || getIsDateRangeFormat || isKFactorAnalysis) return undefined;

        const selectedDataMonths = dataMonths.map(month => `${month.month}-${month.year}`);

        return [...new Set(selectedDataMonths)].join(",");
    },
);

export const serializedSelectedDataPeriodsSelector = createSelector(
    getDataPeriods,
    isDateRangeFormat,
    state => isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.AADT.code, state),
    (dataPeriods, getIsDateRangeFormat, isAADTAnalysis) => {
        if (isAADTAnalysis || !getIsDateRangeFormat) {
            return undefined;
        }

        return dataPeriods.map(dataPeriod => ({
            start_date: moment(dataPeriod.startDate).format("MM/DD/YYYY"),
            end_date: moment(dataPeriod.endDate).format("MM/DD/YYYY"),
        }));
    },
);

export const getDataPeriodsForTravelMode = createSelector(
    getBasics,
    getTimePeriods,
    getIsAvailableSpecificProjectDates,
    (
        { travelModeCode },
        { dataPeriodSettings, dataMonthSettings },
        isAvailableSpecificProjectDates,
    ) => {
        const travelMode = getAnalysisTravelModeFromCode(travelModeCode);
        const isDataPeriodsMode = travelMode.editableDataDateRanges
            ? isAvailableSpecificProjectDates
            : false;
        const [dataPeriodsType, dataPeriodsData] = isDataPeriodsMode
            ? ["dataPeriods", dataPeriodSettings.dataPeriods]
            : ["dataMonths", dataMonthSettings.dataMonths];
        return { dataPeriodsType, dataPeriodsData };
    },
);

// Checks that analysis is for US, and it contains dates with different metrics
// methodologies for traveler attributes: Census 2010 dates (2019 backward) and
// Census 2020 dates (from 2019 forward)
export const getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes = createSelector(
    getBasics,
    getGeneral,
    getDataPeriodsForTravelMode,
    ({ travelModeCode, country }, { analysisTypeCode }, { dataPeriodsType, dataPeriodsData }) => {
        const isAnalysisSupportsTravelerAttributes = getIsAnalysisTypeSupportOption(
            "travelerAttributes",
            analysisTypeCode,
        );
        const isUSAnalysis = country === ORG_COUNTRIES.US.code;
        const isVehicleMode = getIsAllVehiclesTravelMode(travelModeCode);
        const isBikePedMode = getIsBikeOrPedTravelMode(travelModeCode);

        const shouldDisplayNotification =
            (isVehicleMode || isBikePedMode) &&
            isUSAnalysis &&
            isAnalysisSupportsTravelerAttributes;

        if (!shouldDisplayNotification) return false;

        return getIsDataPeriodsMixedWithCensus2020({
            dataPeriodsType,
            dataPeriodsData,
        });
    },
);

export const showWarningForDatesWithDifferentMetricsMethodologiesForTravelerAttributes =
    createSelector(
        getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes,
        state => getIsReadOnlyMode(state),
        getBasics,
        (
            hasDatesWithDifferentMetricsMethodologiesForTravelerAttributes,
            readOnly,
            { censusType },
        ) =>
            hasDatesWithDifferentMetricsMethodologiesForTravelerAttributes &&
            (readOnly ? censusType === ANALYSIS_CENSUS_TYPES.US_2020 : true),
    );

// Checks if analysis supports Census 2020 data (used for Traveler Attributes):
// analyses with US country
export const getIsUSCensus2020Analysis = createSelector(
    getBasics,
    ({ country }) => country === ORG_COUNTRIES.US.code,
);

// Checks that analysis is US and contains some Census 2020 dates (from 2019 forward)
export const getIsUSAnalysisHasCensus2020Dates = createSelector(
    getIsUSCensus2020Analysis,
    getDataPeriodsForTravelMode,
    (isUSCensus2020Analysis, { dataPeriodsType, dataPeriodsData }) => {
        return (
            isUSCensus2020Analysis &&
            getHasCensus2020DataPeriods({
                dataPeriodsType,
                dataPeriodsData,
            })
        );
    },
);

// Checks that analysis is US and contains only Census 2020 dates (from 2019 forward)
export const getIsUSAnalysisWithOnlyCensus2020Dates = createSelector(
    getIsUSCensus2020Analysis,
    getDataPeriodsForTravelMode,
    (isUSCensus2020Analysis, { dataPeriodsType, dataPeriodsData }) => {
        return (
            isUSCensus2020Analysis &&
            getIsOnlyCensus2020DataPeriods({
                dataPeriodsType,
                dataPeriodsData,
            })
        );
    },
);

// Checks if analysis has US Census2020 traveler params
export const getIsUSCensus2020TravelerParams = createSelector(
    getIsUSAnalysisWithOnlyCensus2020Dates,
    state => getIsReadOnlyMode(state),
    getBasics,
    (isUSAnalysisWithOnlyCensus2020Dates, isReadOnly, { censusType }) => {
        return isReadOnly
            ? censusType === ANALYSIS_CENSUS_TYPES.US_2020
            : isUSAnalysisWithOnlyCensus2020Dates;
    },
);

export const getIsAugmentedDataMonthsSelected = createSelector(
    getBasics,
    getDataMonths,
    ({ travelModeCode }, dataMonths) => {
        const isBikePedMode = getIsBikeOrPedTravelMode(travelModeCode);

        if (!isBikePedMode) return false;

        return dataMonths.some(month => month.is_ped_augmented || month.is_bike_augmented);
    },
);

export const getIsTimePeriodWithCVDLimitedTravelerAttrs = createSelector(
    getTimePeriods,
    ({ shouldUseCustomRanges, dataPeriodSettings, dataMonthSettings }) =>
        getHasTimePeriodWithCVDLimitedTravelerAttrs({
            dataMonths: shouldUseCustomRanges ? null : dataMonthSettings.dataMonths,
            dataPeriods: shouldUseCustomRanges ? dataPeriodSettings.dataPeriods : null,
        }),
);

export const getIsCVDUpsampledMetricsDatesSelected = createSelector(
    getBasics,
    getTimePeriods,
    ({ travelModeCode }, { shouldUseCustomRanges, dataPeriodSettings, dataMonthSettings }) => {
        if (travelModeCode !== MODES_OF_TRAVEL.ALL_VEHICLES_CVD.id) return false;

        const [dataPeriodsType, dataPeriodsData] = shouldUseCustomRanges
            ? ["dataPeriods", dataPeriodSettings.dataPeriods]
            : ["dataMonths", dataMonthSettings.dataMonths];

        return getHasCVDUpsampledMetricsDates({
            dataPeriodsType,
            dataPeriodsData,
        });
    },
);
export const getHasExcludedMediumDutyDataPeriodsSelector = createSelector(
    getDataPeriods,
    dataPeriods => hasExcludedMediumDutyDataPeriods(dataPeriods),
);

// For Create Analysis mode, use weekday type from user preferences;
// For other modes, determine it from analysis day types
export const getWeekdayType = createSelector(
    getDayTypeSettings,
    getGeneral,
    getPreferredWeekdayType,
    ({ weekdayType }, { uiStates }, preferredWeekdayType) => {
        const weekdayTypeFromPreferences =
            getWeekdayTypeById(preferredWeekdayType) || WEEKDAY_TYPES.MON_THU;
        if (uiStates.screenMode === SCREEN_MODES.DEFAULT.id) {
            return weekdayTypeFromPreferences;
        }
        return getWeekdayTypeById(weekdayType) || weekdayTypeFromPreferences;
    },
);
